import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Practice from './components/Practice';
import Task from './components/Task';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard></Dashboard>,
  },
  {
    path: "/practice",
    element: <Practice></Practice>,
  },
  {
    path: "/task/:id",
    element: <Task></Task>,
  }
]);


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='container'>
          <Navbar></Navbar>
          <RouterProvider router={router} />
        </div>
      </header>
    </div>
  );
}

export default App;
