import { FC, useEffect, useState } from "react";

interface RotatingTextProps {
    textOptions: string[];
    className: string;
}

const CONSTANTS = {
    DELETING_SPEED: 30,
    TYPING_SPEED: 150,
};

export const RotatingText: FC<RotatingTextProps> = (props) => {

    const [state, setState] = useState({
        text: "",
        message: "",
        isDeleting: false,
        loopNum: 0,
        typingSpeed: CONSTANTS.TYPING_SPEED,
    });

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        const handleType = () => {
            setState((cs) => ({
                ...cs, // cs means currentState
                text: getCurrentText(cs),
                typingSpeed: getTypingSpeed(cs),
            }));
            timer = setTimeout(handleType, state.typingSpeed);
        };
        handleType();
        return () => clearTimeout(timer!);
    }, [state.isDeleting]);

    useEffect(() => {
        if (!state.isDeleting && state.text === state.message) {
            setTimeout(() => {
                setState((cs) => ({
                    ...cs,
                    isDeleting: true,
                }));
            }, 500);
        } else if (state.isDeleting && state.text === "") {
            setState((cs) => ({
                ...cs, // cs means currentState
                isDeleting: false,
                loopNum: cs.loopNum + 1,
                message: getMessage(cs, props.textOptions),
            }));
        }
    }, [state.text, state.message, state.isDeleting, props.textOptions]);

    function getCurrentText(currentState: any) {
        return currentState.isDeleting
            ? currentState.message.substring(0, currentState.text.length - 1)
            : currentState.message.substring(0, currentState.text.length + 1);
    }

    function getMessage(currentState: any, data: any) {
        return data[Number(currentState.loopNum) % Number(data.length)];
    }

    function getTypingSpeed(currentState: any) {
        return currentState.isDeleting
            ? CONSTANTS.TYPING_SPEED
            : CONSTANTS.DELETING_SPEED;
    }

    return (
        <div className={props.className}>
            <span>{state.text}</span>
            <span id="cursor"></span>
        </div>
    );
};
