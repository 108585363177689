import { useState } from "react";
import logowithbrand from "../assets/logoWithBrand.png"
import ContactUsModal from "./ContactUsModal";
import LoginModal from "./LoginModal";

export default function Navbar() {

    const [contactUsModal, setContactUsModal] = useState(false);
    const [loginModal, setloginModal] = useState(false)

    return (
        <nav className="navbar is-spaced has-background-white" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item">
                    <img src={logowithbrand} alt="logowithbrand" style={{ width: 280, maxHeight: 100, height: 40, marginRight: 10 }} />
                </a>

                <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            {contactUsModal && <ContactUsModal showModal={contactUsModal} setShowModal={setContactUsModal}></ContactUsModal>}
            {loginModal && <LoginModal showModal={loginModal} setShowModal={setloginModal}></LoginModal>}


            <div id="navbarBasicExample" className="navbar-menu">
                {/* <div className="navbar-start is-size-6">
                    <a className="navbar-item">
                        Practice
                    </a>

                    <a className="navbar-item">
                        Contact Us
                    </a>
                </div> */}

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {/* <Link to="practice">
                                <button className="button is-primary block" >Get Started</button>
                            </Link> */}
                            <a className="button is-button-green has-text-white" onClick={() => setloginModal(!loginModal)}>
                                <strong>Log in / Sign up</strong>
                            </a>
                            <a className="button" onClick={() => setContactUsModal(!contactUsModal)}>
                                <strong>Contact Us</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
