import { Task } from '../Model/Task';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

export type TaskCardProps = {
    task: Task
};

export default function TaskCard(props: TaskCardProps) {
    return (
        <article className="tile is-child box box-changes-color-on-hover has-text-left">
            <div className="content mb-0">
                <div className="has-text-weight-bold is-size-7 has-text-primary-title-header">Task</div>
            </div>
            <h2 className='is-size-4 has-text-weight-bold'>{props.task.description}</h2>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 5}}>
                <div className='has-text-weight-bold is-size-7' style={{ display: "flex", alignItems: "center" }}>
                    <MoveToInboxIcon></MoveToInboxIcon> 
                    <div style={{paddingLeft: 4}}>20</div>
                </div>

                <div className='has-text-weight-bold is-size-7' style={{ paddingLeft: 15, display: "flex", alignItems: "center" }}>
                    <CommentOutlinedIcon></CommentOutlinedIcon>
                    <div style={{paddingLeft: 4}}>20</div>
                </div>
            </div>
        </article>
    )
}
