// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA9W97sx4QtM_9-sYEpfmDlUnF2LCJVtUM",
    authDomain: "the-right-siders.firebaseapp.com",
    projectId: "the-right-siders",
    storageBucket: "the-right-siders.appspot.com",
    messagingSenderId: "419285581419",
    appId: "1:419285581419:web:dfa1a35880c9887bbfbb2e",
    measurementId: "G-V1P0X5VR54"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(app);
export const analytics = getAnalytics(app);