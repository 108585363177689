import logowithbrand from "../assets/logoWithBrand.png"
import { ModalProp } from '../Props/ModalProps'
import { SetStateAction, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ContactUsModal(props: ModalProp) {

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");

    const [fillAllFormsMessageVisible, setfillAllFormsMessageVisible] = useState(false);
    const [thanksForSubmittingVisible, setthanksForSubmittingVisible] = useState(false);

    const handleMessageChange = (event: { target: { value: SetStateAction<string>; }; }) => { setmessage(event.target.value); };
    const handleEmailChange = (event: { target: { value: SetStateAction<string>; }; }) => { setemail(event.target.value); };
    const handleNameChange = (event: { target: { value: SetStateAction<string>; }; }) => { setname(event.target.value); };

    function sendEmail() {
        var emailParams = {
            name: name,
            email: email,
            message: message
        };

        if (name.length > 0 && message.length > 0) {
            // emailjs.send('service_4dn1o1e', 'template_66ar0mv', emailParams, "rTB7JkavcJwqJED2E")
            //     .then(function (response: { status: any; text: any; }) {
            //         console.log('SUCCESS!', response.status, response.text);
            //         setthanksForSubmittingVisible(true);
            //     }, function (err: any) {
            //         console.log('FAILED...', err);
            //     });
        } else {
            setfillAllFormsMessageVisible(true);
        }
    }

    function closeModal() {
        props.setShowModal(false);
    }

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <div className="box">
                    <img src={logowithbrand} alt="logowithbrand" style={{ width: 280, maxHeight: 100, height: 40, marginBottom: 15 }} />
                    {thanksForSubmittingVisible ?
                        <div>
                            <CheckCircleIcon style={{ width: 130, height: 130, color: "#00c65f" }}></CheckCircleIcon>
                            <div className="content title has-text-weight-bold is-size-4">Thank you for your message!</div>
                            <div className="content subtitle has-text-weight-bold is-size-5">we'll get back to you asap</div>
                            <button className="button is-button-green has-text-white" onClick={() => closeModal()}>Close</button>
                        </div>
                        :
                        <div>
                            <form>
                                <div className="field is-text-right">
                                    <label className="label has-text-left">Name</label>
                                    <div className="control">
                                        <input className="input" value={name} onChange={handleNameChange} type="text" placeholder="John Doe" />
                                    </div>
                                </div>

                                <div className="field is-text-right">
                                    <label className="label has-text-left">Email</label>
                                    <div className="control">
                                        <input className="input" value={email} onChange={handleEmailChange} type="email" placeholder="e.g. john@example.com" />
                                    </div>
                                </div>

                                <div className="field is-text-right">
                                    <label className="label has-text-left">What would you like to tell us?</label>
                                    <textarea value={message} onChange={handleMessageChange} className="textarea is-primary" placeholder="tell us here"></textarea>
                                </div>


                            </form>

                            {fillAllFormsMessageVisible && <label className="label has-text-left has-text-danger mt-3">Please Fill All Fields</label>}

                            <div className="field is-grouped mt-4">
                                <p className="control">
                                    <button className="button is-button-green has-text-white" onClick={() => sendEmail()}>Send</button>
                                </p>
                                <p className="control">
                                    <button className="button is-hovered" onClick={() => closeModal()}>Close</button>
                                </p>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}
