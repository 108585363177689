import React from 'react'
import { Task } from '../Model/Task'
import { addDoc, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { firestoreDB } from '../firebaseConfig';
import { setCurrentTask, setTasks } from '../slices/TasksSlice';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../app/store';
import { Action } from '@reduxjs/toolkit';

import tempData from "../assets/mockData.json"
import { getTaskMessages } from './TaskDiscussionService';

export const writeTask = async (task: Task) => {
    try {
        const docRef = await addDoc(collection(firestoreDB, "tasks"), task);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const getTasks = (): ThunkAction<void, RootState, null, Action> =>
    async (dispatch, getState) => {
        var tempStore: Task[] = [];

        // tempData.map(task => {
        //     writeTask(task);
        // })

        const querySnapshot = await getDocs(collection(firestoreDB, "tasks"));
        console.log(querySnapshot.docs.length);
        querySnapshot.forEach((doc) => {
            var task: Task = doc.data() as Task
            task.id = doc.id;
            tempStore.push(task);
        });

        dispatch(setTasks(tempStore));
    };

export const getTask = (taskID:string): ThunkAction<void, RootState, null, Action> =>
    async (dispatch, getState) => {
        const docSnap = await getDoc(doc(firestoreDB, "tasks", taskID));
        if (docSnap.exists()) {
            var task: Task = docSnap.data() as Task
            task.id = docSnap.id;

            dispatch(setCurrentTask(task));
          } else {  
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
    };
