import AboutUs from './AboutUs'
import InfoHero from './InfoCard'
import Timeline from './Timeline'
import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { analytics } from '../firebaseConfig'
import { AnalyticsKeys } from '../enums/AnalyticsKeys'

export default function Dashboard() {

  useEffect(() => {
    console.log(AnalyticsKeys.screen_land.toString())
    logEvent(analytics, AnalyticsKeys.screen_land.toString(), { name: 'lever_puzzle' });
  })

  return (
    < div >
      <InfoHero></InfoHero>
      <AboutUs></AboutUs>
      <Timeline></Timeline>
    </div >
  )
}
