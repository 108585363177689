import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import type {} from 'redux-thunk/extend-redux'

//TEMP IMPORT FROM LOCAL JSON
import { TaskMessage } from '../Model/TaskMessage'

// Define a type for the slice state
interface TaskDiscussionState {
  messages: TaskMessage[]
}

// Define the initial state using that type
const initialState: TaskDiscussionState = {
    messages: [],
}

export const taskDiscussionSlice = createSlice({
  name: 'taskDiscussion',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTaskMessages: (state, action: PayloadAction<TaskMessage[]>) => {
      state.messages = action.payload
    }
  },
})

// Other code such as selectors can use the imported `RootState` type
export const taskMessages = (state: RootState) => state.taskMessages

export const { setTaskMessages } = taskDiscussionSlice.actions

export default taskDiscussionSlice.reducer