import React from 'react'
import dizzy from "../assets/dizzy.png"
import keyboard from "../assets/keyboard.png"
import panel from "../assets/panel.png"
import logo from "../assets/logoOnlyIcon.png"

export default function AboutUs() {
    return (
        <div>
            <p className="landing-page-title block is-size-2-mobile has-text-primary" style={{ marginTop: 100 }}>
                why is this a startup?
            </p>

            <section className="section is-medium">
                <div className="columns is-variable is-4">
                    <div className="column">
                        <img src={dizzy} alt="practiceIcon" style={{ width: 100, height: 100, marginBottom: 15 }} />
                        <div className="content has-text-weight-bold has-text-primary">Hi, my name is Surya. I was a miserable engineer and decided to make a career change</div>
                    </div>
                    <div className="column">
                        <img src={keyboard} alt="practiceIcon" style={{ width: 100, height: 100, marginBottom: 15 }} />
                        <div className="content has-text-weight-bold has-text-primary">We engineers have platforms like LeetCode to practice and learn from feedback</div>
                    </div>
                    <div className="column">
                        <img src={panel} alt="practiceIcon" style={{ width: 100, height: 100, marginBottom: 15 }} />
                        <div className="content has-text-weight-bold has-text-primary">I couldn’t find a structured practice platform for creators with interesting prompts and helpful peers</div>
                    </div>
                    <div className="column">
                        <img src={logo} alt="practiceIcon" style={{ width: 100, height: 100, marginBottom: 15 }} />
                        <div className="content has-text-weight-bold has-text-primary">So we built a platform to practice creative problems and grow as a community</div>
                    </div>
                </div>
            </section>
        </div>
    )
}
