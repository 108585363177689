import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Filter2Icon from '@mui/icons-material/Filter2';

export default function Timeline() {
    return (
        <div style={{ backgroundColor: "#1f7c4b" }}>
            <p className="landing-page-title block is-size-2-mobile has-text-white" style={{ paddingTop: 50, paddingBottom: 20 }}>
                what is our plan?
            </p>

            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: 'white' }}
                    icon={<LightbulbIcon style={{ color: "white" }} />}
                >
                    <div>
                        <div className='content subtitle has-text-right has-text-left-mobile has-text-primary has-text-weight-bold is-italic'>Mid of September, 2022</div>
                        <div className="content title is-size-5 has-text-right has-text-left-mobile">The idea for The Right Siders was conceptualized. We identified competitors and our target audience to judge the feasibility of the startup</div>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: '#fff' }}
                    icon={<ConstructionIcon style={{ color: "white" }} />}
                >
                    <div>
                        <div className='content subtitle has-text-left has-text-primary has-text-weight-bold is-italic'>End of September, 2022</div>
                        <div className="content title is-size-5 has-text-left">Registered domain name and started building the MVP which consists of a landing page with startup details and a list of questions with email submission for feedback</div>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: '#fff' }}
                    icon={<RocketLaunchIcon style={{ color: "white" }} />}
                >
                    <div>
                        <div className='content subtitle has-text-right has-text-left-mobile has-text-primary has-text-weight-bold is-italic'>October 3, 2022</div>
                        <div className="content title is-size-5 has-text-right has-text-left-mobile">Release MVP and test it around with current design students and schools to make the practice scenarios and experience better</div>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: '#fff' }}
                    icon={<Filter2Icon style={{ color: "white" }} />}
                >
                    <div>
                        <div className='content subtitle has-text-left has-text-primary has-text-weight-bold is-italic'>October 5, 2022</div>
                        <div className="content title is-size-5 has-text-left">Release features to upload submissions for the task and get feedback through email</div>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: '#fff' }}
                    icon={<RocketLaunchIcon style={{ color: "white" }} />}
                >
                    <div>
                        <div className='content subtitle has-text-right has-text-left-mobile has-text-primary has-text-weight-bold is-italic'>October 7, 2022</div>
                        <div className="content title is-size-5 has-text-right has-text-left-mobile">Release feedback feature on the platform. This is the end of feature development. Our focus is now adding scenarios and finding our market fit</div>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#1f7c4b', color: '#fff' }}
                    icon={<Filter2Icon style={{ color: "white" }} />}
                ></VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}