import { useState, useEffect, useRef } from "react";

import SendIcon from '@mui/icons-material/Send';
import { ChatFormProps } from "../Props/ChatFormProps";

export default function MessageInput(props: ChatFormProps) {

    const [message, setMessage] = useState("")

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <input value={message} onChange={(e) => setMessage(e.target.value)} style={{ marginRight: 5, height: 36 }} className="input" type="text" placeholder="Type message here..." />
            <button onClick={() => props.sendMessage(message)} style={{ border: "none", background: "none" }}>
                <SendIcon className="is-primary"></SendIcon>
            </button>
        </div>
    );
}