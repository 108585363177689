import { ChatFeed, Message } from 'react-chat-ui'
import React, { useEffect, useState } from 'react'
import MessageInput from './MessageInput';
import { writeTaskMessage } from '../Service/TaskDiscussionService';
import { TaskMessage } from '../Model/TaskMessage';
import { useAppSelector } from '../app/hooks';
import { currentTask } from '../slices/TasksSlice';
import { taskMessages } from '../slices/TaskDiscussionSlice';

export default function Discussion() {

    const task = useAppSelector(currentTask);
    const disscussionMessages = useAppSelector(taskMessages).messages;

    const [messages, setmessages] = useState([] as Message[]);

    useEffect(() => {
        disscussionMessages.forEach(message => {
            var msg = {
                id: 0,
                senderName: "test",
                message: message.message
            } as Message

            messages.push(msg);
        });
    }, [disscussionMessages])


    function sendMessage(message: string) {
        var taskMessage: TaskMessage = {
            id: "",
            task_id: task?.id ?? "",
            user_id: "test",
            message: message,
            datetime: Date.now()
        } as TaskMessage

        writeTaskMessage(taskMessage);
    }

    return (
        <div>
            <ChatFeed
                messages={messages} // Array: list of message objects
                isTyping={false} // Boolean: is the recipient typing
                hasInputField={false} // Boolean: use our input, or use your own
                showSenderName // show the name of the user who sent the message
                bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                // JSON: Custom bubble styles
                bubbleStyles={
                    {
                        text: {
                            fontSize: 15,
                            fontWeight: "bold"
                        },
                        chatbubble: {
                            borderRadius: 100,
                            padding: 10
                        }
                    }
                }
            />

            <MessageInput sendMessage={sendMessage}></MessageInput>
        </div>
    );
}
