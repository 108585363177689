import React, { useEffect, useState } from 'react'
import 'bulma/css/bulma.min.css';
import TaskCard from './ScenarioCard';
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { tasks } from '../slices/TasksSlice';
import { getTasks } from '../Service/TaskService';
import { Link } from 'react-router-dom';

export default function Practice() {

    const taskList = useAppSelector(tasks);
    const dispatch = useAppDispatch();
    const skills = ["Ui/Ux Design", "Product Design", "Marketing", "Branding"];

    const [selectedSkill, setselectedSkill] = useState("");

    function setSkill(skill: string) {
        setselectedSkill(skill);
    }

    useEffect(() => {
        dispatch(getTasks())
    }, [])

    return (
        <div>
            <div className="section has-background-primary is-small is-justify-content-center mb-4">
                <p className="landing-page-title title is-1 block is-size-2-mobile has-text-white" style={{}}>
                    Pick a skill to practice
                </p>

                <div>
                    <div className="buttons">
                        {skills.map(skill => {
                            return (
                                <button className={"button is-white is-solid"} onClick={() => setSkill(skill)}>{skill}</button>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="columns is-multiline">
                {taskList.map(task => {
                    if (selectedSkill === task.skill) {
                        return (
                            <div className="column is-4">
                                <Link to={`/task/${task.id}`}>
                                    <TaskCard task={task}></TaskCard>
                                </Link>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

