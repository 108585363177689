import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tasksReducer from "../slices/TasksSlice"
import taskMessagesReducer from "../slices/TaskDiscussionSlice"

export const store = configureStore({
  reducer: {
    tasks: tasksReducer,
    taskMessages: taskMessagesReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
