import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import type {} from 'redux-thunk/extend-redux'

//TEMP IMPORT FROM LOCAL JSON
import { Task } from '../Model/Task'

// Define a type for the slice state
interface TasksState {
  tasks: Task[],
  currentTask: Task | null
}

// Define the initial state using that type
const initialState: TasksState = {
  tasks: [],
  currentTask: null
}

export const tasksSlice = createSlice({
  name: 'tasks',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload
    },
    setCurrentTask: (state, action: PayloadAction<Task | null>) => {
      state.currentTask = action.payload
    }
  },
})

// Other code such as selectors can use the imported `RootState` type
export const tasks = (state: RootState) => state.tasks.tasks
export const currentTask = (state: RootState) => state.tasks.currentTask

export const { setTasks, setCurrentTask } = tasksSlice.actions

export default tasksSlice.reducer