import { Chip, Divider } from '@mui/material'
import React, { useState } from 'react'
import logowithbrand from "../assets/logoWithBrand.png"
import { ModalProp } from '../Props/ModalProps'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';

export default function LoginModal(props: ModalProp) {

    function closeModal() {
        props.setShowModal(false);
    }


    const responseGoogle = (response: any) => {
        console.log(response);
    }

    const [showLogin, setshowLogin] = useState(false);

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <div className="container">
                    <form className="box">
                        <img src={logowithbrand} alt="logowithbrand" style={{ width: 280, maxHeight: 100, height: 40, marginBottom: 15 }} />

                        <div className="content">
                            {/* <GoogleLogin
                                buttonText='Continue With Google'
                                className='is-size-6'
                                clientId="419285581419-ii4esar9dto3c88lt6ddtsf8dl98oqe0.apps.googleusercontent.com"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            /> */}

                            <p className="control has-text-centered mt-3">
                                <button className="button is-light" onClick={() => closeModal()}>
                                    <CloseIcon></CloseIcon>
                                </button>
                            </p>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}
