import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getTaskMessages } from '../Service/TaskDiscussionService';
import { getTask } from '../Service/TaskService';
import { currentTask } from '../slices/TasksSlice';
import Discussion from './Discussion';

export default function Task() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();
    const dispatch = useAppDispatch();

    const task = useAppSelector(currentTask);

    useEffect(() => {
        id != undefined && dispatch(getTask(id));
    }, [])

    useEffect(() => {
        dispatch(getTaskMessages(id ?? ""));
    }, [id])

    return (
        <div className="tile is-ancestor">
            <div className="tile is-vertical is-8">
                <div className="tile is-parent has-text-left">
                    <article className="tile is-child box">
                        <div className="columns">

                            {/* TASK DISPLAY */}
                            <div className="column is-three-quarters">
                                <div className="has-text-weight-bold is-size-7 has-text-primary-title-header">Task</div>
                                <p className="title is-size-4">{task?.description}</p>
                            </div>

                            {/* TOOLS AND TIPS DISPLAY */}
                            <div className="column">
                                <div className="has-text-weight-bold is-size-7 has-text-primary-title-header">Skill</div>
                                <span className="tag is-info is-large is-light has-text-weight-bold">{task?.skill}</span>
                            </div>
                        </div>
                    </article>
                </div>
                <div className="tile is-parent has-text-left">
                    <article className="tile is-child box">
                        <p className="title is-size-4">Other Submissions</p>
                    </article>
                </div>
            </div>
            <div className="tile is-parent has-text-left">
                <article className="tile is-child box tile-has-green-border">
                    <div className="content">
                        <p className="title is-size-4 mb-1">Discussion</p>
                        <div className="has-text-weight-bold is-size-7 has-text-primary-title-header">Ask questions and discuss with peers</div>
                        <Discussion></Discussion>
                    </div>
                </article>
            </div>
        </div>
    );
}
