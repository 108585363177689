import practiceIcon from "../assets/practice.png"
import collaboration from "../assets/collaboration.png"
import growing from "../assets/growing.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { RotatingText } from "../RotatingText/RotatingText"

const messages = [
    "ui/ux designers",
    "advertisers",
    "product designers",
    "branding agents",
];

export default function InfoHero() {
    return (
        <section className="hero is-medium has-background-white">
            <div className="hero-body">
                <p className="landing-page-title block is-size-2-mobile has-text-gray">
                    The learning platform for <div className='has-text-primary'>
                        <RotatingText
                            textOptions={messages}
                            className="text-pink-40 md:t__heading--h2 t__heading--h3"
                        />
                    </div>
                </p>

                <div className="columns block">
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-content">
                                <div className="content has-text-weight-bold has-text-primary">PRACTICE</div>
                                <img src={practiceIcon} alt="practiceIcon" style={{ width: 100, height: 100 }} />
                                <div className="content has-text-weight-bold">
                                    Practice real-world creative problems
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-content">
                                <div className="content has-text-weight-bold has-text-primary">COLLABORATE</div>
                                <img src={collaboration} alt="practiceIcon" style={{ width: 100, height: 100 }} />
                                <div className="content has-text-weight-bold">
                                    Get feedback from peers and professionals
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-content">
                                <div className="content has-text-weight-bold has-text-primary">GROW</div>
                                <img src={growing} alt="practiceIcon" style={{ width: 100, height: 100 }} />
                                <div className="content has-text-weight-bold">
                                    Grow with all the skills we offer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Link to="/practice">
                    <button className="button is-button-green has-text-weight-bold">
                        <span className="has-text-white" style={{ marginRight: 5 }}>Start Growing</span>
                        <FontAwesomeIcon icon={faArrowRight} className="fa-small color:white" style={{ color: "white" }} />
                    </button>
                </Link>
            </div>
            <div className="hero-footer">
                <FontAwesomeIcon icon={faArrowDown} className="fa-small" style={{ color: "#1f7c4b", maxHeight: 21, maxWidth: 21, height: 21, width: 21 }} />
                <div className="content has-text-weight-bold is-size-5 has-text-primary">Scroll Down To Learn More</div>
            </div>
        </section>
    )
}