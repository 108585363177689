import React from 'react'
import { Task } from '../Model/Task'
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { firestoreDB } from '../firebaseConfig';
import { setCurrentTask, setTasks } from '../slices/TasksSlice';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../app/store';
import { Action } from '@reduxjs/toolkit';
import { TaskMessage } from '../Model/TaskMessage';
import { setTaskMessages } from '../slices/TaskDiscussionSlice';


export const writeTaskMessage = async (message: TaskMessage) => {
    try {
        const docRef = await addDoc(collection(firestoreDB, "task_messages"), message);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const getTaskMessages = (taskId:string): ThunkAction<void, RootState, null, Action> =>
    async (dispatch, getState) => {
        var tempStore: TaskMessage[] = [];

        const q = query(collection(firestoreDB, "task_messages"), where("task_id", "==", taskId));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());

            var message: TaskMessage = doc.data() as TaskMessage
            message.id = doc.id;
            tempStore.push(message);

        });

        dispatch(setTaskMessages(tempStore));
    };